import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const Privacy = () => {
 
    const [active, setActive] = useState(false);
    const [searchShow, setSearchShow] = useState(false);
    const menuActive = () => {
      setActive(!active);
    };
    const searchActive = () => {
      setSearchShow(!searchShow);
      console.log("hell");
    };
  
    // Control sidebar navigation
    let items = document.querySelectorAll(".menu-item-has-children > a");
    for (let i in items) {
      if (items.hasOwnProperty(i)) {
        items[i].onclick = function () {
          this.parentElement
            .querySelector(".sub-menu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
  


  return (
    <>
      {/* search popup start*/}
      {/* <div
  className='breadcrumb-area bg-cover'
  style={{
    backgroundImage: 'url("./assets/img/bg/7.png")',
    padding: '8% 0% 2% 0%'
    // You can adjust this value to your desired height
  }}
> */}
    
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id='td-search-popup'
      >
        <form action='/' className='search-form'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Search.....'
            />
          </div>
          <button type='submit' className='submit-btn'>
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id='body-overlay'
      ></div>
      {/* navbar start */}
      <nav className='navbar navbar-area navbar-expand-lg'>
        <div className='container nav-container navbar-bg'>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src='assets/img/logo/ht.png' style={{width:"500px",maxWidth:"300px"}} alt='img' />
            </Link>
          </div>
          <div className='nav-right-part nav-right-part-mobile'>
            <span className='search-bar-btn' onClick={searchActive}>
              <FaSearch />
            </span>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-end'>
              <li className=''>
               <Link to='/'>Home</Link> 

              </li>

              <li>
                <Link to='/contact'>Contact Us</Link>
              </li>
            </ul>
          </div>
          {/*<div className='nav-right-part nav-right-part-desktop align-self-center'>*/}
          {/*  <a className='navbar-phone' href='tel:'>*/}
          {/*    <span className='icon'>*/}
          {/*      <img src='assets/img/icon/1.png' alt='img' />*/}
          {/*    </span>*/}
          {/*    <span>For queries</span>*/}
          {/*    <h5>(+91) 8667730776</h5>*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </nav>
      {/* </div> */}
      <div className="contact-page-inner bg-gray">
      <h2>Privacy Policy</h2>
      <div>
       <p>
       This Privacy Policy outlines how BharatAves ("we", "our", or "us") collects, uses, discloses, and safeguards personal information obtained through our drone-based website. By accessing or using our website, you agree to the terms of this Privacy Policy.
       <br/></p>
<p>
<h6>Information Collection:</h6>

We may collect personal information (such as name, contact details, and location) when you interact with our website, subscribe to our newsletter, or submit inquiries.
<br /></p>
<p>
<h6>Information Usage:</h6>
We use collected information to respond to inquiries, provide services, improve user experience, and communicate updates. We may also use aggregated data for analytical purposes.
<br/> </p>
<p>
<h6>Information Sharing:</h6>
We do not sell, trade, or share personal information with third parties for marketing purposes. However, we may share information with service providers to fulfil requests or improve our website.
<br/> </p>
<p>
<h6>Cookies and Tracking:</h6>
We use cookies and similar technologies to enhance user experience and gather usage data. You can adjust browser settings to manage cookies.
<br/> </p>
<p>
<h6>Data Security:</h6>
We employ reasonable security measures to protect personal information. However, no method is entirely secure; we cannot guarantee absolute data protection.
<br/> </p>
<p>
<h6>Links to External Sites:</h6>
Our website may contain links to third-party sites. We are not responsible for their content or privacy practices. Please review their privacy policies.
<br/> </p> 
<p>
<h6>Children's Privacy:</h6>
Our website is not intended for individuals under 13 years of age. We do not knowingly collect personal information from children.
<br/> </p>
<p>
<h6>Updates to Privacy Policy:</h6>
We may update this Privacy Policy periodically. The updated version will be posted on our website with the effective date.
<br/> </p>
<p>
<h6>Your Rights:</h6>
You have the right to access, correct, or delete your personal information. To do so, please contact us using the information provided below.
<br/> </p>
<p>
<h6>Contact Us:</h6>
If you have questions or concerns about this Privacy Policy, please contact us at +91-8667730776 or contact@bharataves.com.
<br/>
This Privacy Policy is effective as of 04-08-2023. Your continued use of our website signifies your acceptance of this policy.

       </p>
    </div>
   </div>
      {/* navbar end */}
    </>
  );
};

export default Privacy