import React from "react";
import { FaArrowRight, FaRegFolderOpen, FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";
const BlogAreaThree = () => {
  return (
    <>
      {/*===================== Blog Area Three start =====================*/}
      <div className='blog-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title'>
            <div className='row'>
              <div className='col-lg-7'>
                <h6 className='sub-title' >RECENT BLOG</h6>
                <h2 className='title'>
                  Collabaration Applications At <span>Affordable</span> Prices
                </h2>
              </div>
              <div className='col-lg-5 text-lg-end align-self-end'>
                <Link className='btn btn-border-base' to='/blog'>
                  More Blogs
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-blog-list'>
                <div className='thumb'>
                  <img
                    className='border-radius-5'
                    src='assets/img/blog/pg 1.png'
                    alt='img'
                  />
                  <p className='date'>4 August,2023</p>
                </div>
                <div className='details'>
                  <ul className='blog-meta p-0'>
                    <li>
                      <FaRegUser /> By Admin
                    </li>
                    <li>
                      <FaRegFolderOpen /> Prototyping
                    </li>
                  </ul>
                  <h5 className='mb-4'>
                    <Link to='/BlogDetails'>
                    The Rise of Delivery Drones: A Brief Overview
                    </Link>
                  </h5>
                  <Link className='btn btn-border-base' to='/BlogDetails'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-blog-list'>
                <div className='thumb'>
                  <img
                    className='border-radius-5'
                    src='assets/img/blog/pg 3.png'
                    alt='img'
                  />
                  <p className='date'>8 August,2023</p>
                </div>
                <div className='details'>
                  <ul className='blog-meta p-0'>
                    <li>
                      <FaRegUser /> By Admin
                    </li>
                    <li>
                      <FaRegFolderOpen /> Surveillance
                    </li>
                  </ul>
                  <h5 className='mb-4'>
                    <Link to='/BlogDetails1'>
                    How Delivery Drones Work: Behind the Scenes
                    </Link>
                  </h5>
                  <Link className='btn btn-border-base' to='/BlogDetails1'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-blog-list'>
                <div className='thumb'>
                  <img
                    className='border-radius-5'
                    src='assets/img/blog/pg 2.png'
                    alt='img'
                  />
                  <p className='date'>14 August,2023</p>
                </div>
                <div className='details'>
                  <ul className='blog-meta p-0'>
                    <li>
                      <FaRegUser /> By Admin
                    </li>
                    <li>
                      <FaRegFolderOpen /> Advertisement
                    </li>
                  </ul>
                  <h5 className='mb-4'>
                    <Link to='/BlogDetails2'>
                    Transforming E-commerce and Retail
                    </Link>
                  </h5>
                  <Link className='btn btn-border-base' to='/BlogDetails2'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===================== BlogAreaThree End =====================*/}
    </>
  );
};

export default BlogAreaThree;
