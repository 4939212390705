import React, { useRef } from "react";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

const ContactMain = () => {
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://bharataves.com:4000/contactus", {
        name: form.current.name.value,
        mail: form.current.mail.value,
        message: form.current.message.value,
      });

      if (response.status === 200) {
        toast.success("Message Sent Successfully!");
        form.current.reset();
      } else {
        toast.error("Message Not Sent!");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Message Not Sent!");
    }
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <div className="contact-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="contact-page-inner bg-gray" style={{marginTop:'-130px'}}>
            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      name="name"
                      type="text"
                      id="inputtext"
                      placeholder="Enter Your Name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      name="mail"
                      type="mail" 
                      id="inputtext"
                      placeholder="Enter Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="single-input-inner">
                    <textarea
                      name="message"
                      rows="5"
                      id="inputtext"
                      placeholder="Enter Your Message..."
                      required
                    />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button className="btn btn-base border-radius-5" type="submit" id="buttontext" >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="title-contact">
            <h2 className="title-contact1">Contact us?</h2>
            <p className="title-contact-para">
            11/4, Pooja Garden, <br/>
            Kalapatti Main Road, <br/>
            Coimbatore, 
            India, <br/>
            Tamil Nadu.<br/>
            Phone:+91 8667730776
            </p>
            <ul className="social-media">
              <li className="title-contact">
                <a href="https://www.facebook.com/bharataves">
                  <FaFacebookF />
                </a>
              </li>
              <li className="title-contact">
                <a href="https://twitter.com/bharataves/">
                  <FaTwitter />
                </a>
              </li>
              <li className="title-contact">
                <a href="https://www.instagram.com/bharataves/">
                  <FaInstagram />
                </a>
              </li>
              <li className="title-contact">
                <a href="https://youtube.com/@BharatAves">
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMain;

