// import React, { useState } from 'react';
// import {
//   FaChevronRight,
//   FaFacebookF,
//   FaInstagram,
//   FaTwitter,
//   FaYoutube,
// } from 'react-icons/fa';
// import { toast, Toaster } from 'react-hot-toast';
// import Modal from 'react-modal';
// import 'react-responsive-modal/styles.css';
// import { Link } from 'react-router-dom'; 
// import Swal from 'sweetalert2';

// Modal.setAppElement('#root');

import React, { useState } from 'react';
import {
  FaChevronRight,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { toast, Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2'; // Import sweetalert2
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';

const FooterThree = () => {
  // const [email, setEmail] = useState('');

  // const handleSubscribe = () => {
  //   const apiURL = 'http://192.168.1.12:4000/subscribe';

  //   fetch(apiURL, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ email }),
  //   })
  //     .then(response => {
  //       if (response.status === 200) {
  //         alert(
  //           'Thank you for subscribing!\nYou have successfully subscribed to our list. We will let you know when we launch.'
  //         );
  //         setEmail(''); // Reset email input field
  //       } else if (response.status === 400) {
  //         toast.error('Subscription already exists');
  //       } else {
  //         toast.error('An error occurred. Please try again later.');
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error:', error);
  //       toast.error('An error occurred. Please try again later.');
  //     });
  // };


  const [email, setEmail] = useState('');

  const handleSubscribe = () => {
    const apiURL = 'http://bharataves.com:4000/subscribe';

    fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then(response => {
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Thank you for subscribing!',
            text: 'You have successfully subscribed to our list. We keep you updated.',
          });
          setEmail(''); // Reset email input field
        } else if (response.status === 400) {
          Swal.fire({
            icon: 'fail',
            title: 'Subscription already exists',
          //  text: 'You have successfully subscribed to our list. We will let you know when we launch.',
          });
          setEmail('');
         // toast.error('Subscription already exists');
        } else {
          toast.error('An error occurred. Please try again later.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again later.');
      });
  };


  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <footer className="footer-area bg-black mt-0 pd-top-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img
                    src="assets/img/logo/bh.png"
                    style={{ width: '300px', maxWidth: '300px' }}
                    alt="img"
                  />
                </div>
                <div className="details">
             
                  {/* <p>
                    Melbourne is simply is dumiomy is text Lorem Ipsum is simply
                  </p> */}
                  <ul className='social-media'>
                    <li>
                      <a href='https://www.facebook.com/bharataves'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='https://twitter.com/bharataves/'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='https://www.instagram.com/bharataves/'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='https://youtube.com/@BharatAves'>
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                
                  {/* ... */}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 offset-xl-2 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                  <li>
                    <a href="#test">Prototyping</a>
                  </li>
                  <li>
                    <a href="#test">Surveillance</a>
                  </li>
                  <li>
                    <a href="#test">Advertisement</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 offset-xl-1 col-md-6">
              <div className="widget widget_about">
                <div className="subscribe mt-4">
                  <input
                    type="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <button onClick={handleSubscribe}>
                    <FaChevronRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-gray-black">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>©BharatAves 2023 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <a href="Terms">Terms &amp; Condition</a>
                <a href="Privacy">Privacy Policy</a>
                <a href="contact">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <Modal
        open={modalIsOpen}
        onClose={closeModal}
        center
        classNames={{ overlay: 'custom-overlay', modal: 'custom-modal' }}
      >
        <h2>Thank you for subscribing!</h2>
        <p>You have successfully subscribed to our list. We will let you know when we launch.</p>
        <button onClick={closeModal}>Close</button>
      </Modal> */}
    </>
  );
};

export default FooterThree;
