import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const Terms = () => {
    

    const [active, setActive] = useState(false);
    const [searchShow, setSearchShow] = useState(false);
    const menuActive = () => {
      setActive(!active);
    };
    const searchActive = () => {
      setSearchShow(!searchShow);
      console.log("hell");
    };
  
    // Control sidebar navigation
    let items = document.querySelectorAll(".menu-item-has-children > a");
    for (let i in items) {
      if (items.hasOwnProperty(i)) {
        items[i].onclick = function () {
          this.parentElement
            .querySelector(".sub-menu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
  


  return (
    <>
      {/* search popup start*/}
      {/* <div
  className='breadcrumb-area bg-cover'
  style={{
    backgroundImage: 'url("./assets/img/bg/7.png")',
    padding: '8% 0% 2% 0%'
    // You can adjust this value to your desired height
  }}
> */}
    
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id='td-search-popup'
      >
        <form action='/' className='search-form'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Search.....'
            />
          </div>
          <button type='submit' className='submit-btn'>
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id='body-overlay'
      ></div>
      {/* navbar start */}
      <nav className='navbar navbar-area navbar-expand-lg'>
        <div className='container nav-container navbar-bg'>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src='assets/img/logo/ht.png' style={{width:"500px",maxWidth:"300px"}} alt='img' />
            </Link>
          </div>
          <div className='nav-right-part nav-right-part-mobile'>
            <span className='search-bar-btn' onClick={searchActive}>
              <FaSearch />
            </span>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-end'>
              <li className=''>
               <Link to='/'>Home</Link> 

              </li>

              <li>
                <Link to='/contact'>Contact Us</Link>
              </li>
            </ul>
          </div>
          {/*<div className='nav-right-part nav-right-part-desktop align-self-center'>*/}
          {/*  <a className='navbar-phone' href='tel:'>*/}
          {/*    <span className='icon'>*/}
          {/*      <img src='assets/img/icon/1.png' alt='img' />*/}
          {/*    </span>*/}
          {/*    <span>For queries</span>*/}
          {/*    <h5>(+91) 8667730776</h5>*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </nav>
      {/* </div> */}
      <div className="contact-page-inner bg-gray">
      <h2>Terms and Condition</h2>
      <div>
        <p>Welcome to BhartAves("we", "our", or "us"). By accessing or using our drone-based website, you agree to comply with and be bound by these Terms and Conditions. Please read these terms carefully before using our website.
</p>
<p><h6>Use of Website:</h6> You agree to use our website for lawful purposes only and not engage in any activities that may compromise its functionality or security.
</p>
<p><h6>Intellectual Property:</h6> All content on our website, including text, images, logos, and videos, is protected by intellectual property laws. You may not use, reproduce, or distribute our content without our explicit consent.
</p>
<p><h6>Disclaimer of Liability:</h6> We strive to provide accurate and up-to-date information, but we do not warrant the accuracy, completeness, or reliability of any content. You use our website at your own risk.
</p>
<p><h6>Third-Party Links:</h6> Our website may contain links to external sites. We are not responsible for the content or practices of these third-party websites. You access them at your own risk.
</p>
<p><h6>Privacy Policy:</h6> Our Privacy Policy explains how we collect, use, and protect your personal information. By using our website, you agree to the terms outlined in our Privacy Policy.
</p>
<p><h6>Modification of Terms:</h6> We reserve the right to modify or update these Terms and Conditions at any time. Your continued use of our website after such changes constitutes your acceptance of the modified terms.
</p>
<p><h6>Limitation of Liability:</h6> We shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our website or any content therein.
</p>
<p><h6>Contact Us:</h6> If you have questions or concerns about these Terms and Conditions, please contact us at +91-8667730776 or contact@bharataves.com.
</p>
<p>By using our website, you acknowledge that you have read, understood, and agree to these Terms and Conditions. If you do not agree with these terms, please do not use our website. These Terms and Conditions are effective as of 04-08-2023.
</p>
    </div>
    </div>
      {/* navbar end */}
    </>
  );
};

export default Terms