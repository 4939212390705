import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceAreaThree = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-120 pd-bottom-90' id="test">
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-8 col-lg-10'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>CREATIVE SERVICES</h6>
                <h2 className='title'>
                Best Destination To Get Your <span>Own customized</span> drones
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner style-3'>
                <div className='thumb'>
                  <img src='assets/img/service-icon/prototype.png' alt='img' 
                  style={{width: '50px'}}/>
                </div>
                <div className='details'>
                  <h5>
                    <Link to='/service-details'>Prototyping</Link>
                  </h5>
                  <p>
                  From Blueprint to Beyond: UAV prototyping takes your ambitions 
                  airborne. Our skilled team crafts custom drones, blending visionary design,
                   top-tier components, and rigorous testing for optimal performance
                  </p>
                  {/* <Link className='read-more-text' to='/service-details'>
                    Touch More <FaPlus />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner style-3'>
                <div className='thumb'>
                  <img src='assets/img/service-icon/monitor.png' alt='img' 
                  style={{width: '50px'}}/>
                </div>
                <div className='details'>
                  <h5>
                    <Link to='/service-details'>Surveillance</Link>
                  </h5>
                  <p>
                  Experience tailored UAV prototyping for building sites. Our 
                  drones redefine surveillance and headcount calculation, delivering 
                  real-time insights to streamline construction progress and enhance 
                  safety
                  </p>
                  {/* <Link className='read-more-text' to='/service-details'>
                    Touch More <FaPlus />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner style-3'>
                <div className='thumb'>
                  <img src='assets/img/service-icon/banner.png' alt='img'
                  style={{width: '50px'}} />
                </div>
                <div className='details'>
                  <h5>
                    <Link to='/service-details'>Advertisement</Link>
                  </h5>
                  <p>
                  Elevate your brand with SkyView Drones. Transform landscapes into branded canvases, projecting logos and messages for unforgettable aerial experiences. Contact us for magic in the skies.
                  </p>
                  {/* <Link className='read-more-text' to='/service-details'>
                    Touch More <FaPlus />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaThree;
