import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import img1 from "../../src/components/R.png"
import img2 from "../../src/components/drone-png-free-2.png"
import img3 from '../../src/components/s1.png'
import img4 from '../../src/components/nnnn.png'
import img5 from '../../src/components/4.png'

const BannerThree = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== BannerThree Start ==================*/}
      <div
        className='banner-area bg-relative banner-area-2 bg-cover'
        style={{backgroundColor: "rgba(211, 211, 211, 0.5)"}}
//         style={{ backgroundImage: 'url("./assets/img/banner-3/6.jpg")',
// width: '100%'
      
//      }}
     
     

      >
        
         {/* <img
                    className='main-img'
                    src={img5}
                  //   style={{
                  //     width: '10px',
                  
                  
                  
                  
                  // }}
                    // style={{ width: '550px', height: '550px', borderRadius: '50%' }}
                    alt='img'
                    
                  /> */}
        {/* <img
  className='bg-img-2'
  src='assets/img/banner-3/4.png'
  alt='img'
  style={{
    transform: 'rotate(180deg)', // Adjust the rotation angle as needed
   marginTop: '4.3%',
    marginRight: '50%',
    width: '50%', // Adjust the width to make the image larger
    height: '100%',
  }}
/> */}

        {/* <img className='bg-img-2' src='assets/img/banner-3/5.png' alt="img" 
       /> */}
        {/* <img className='bg-img-2' src='assets/img/banner-3/4.png' alt='img' /> */}
        <div className='container'
        style={{ marginTop: '-23px'}}>
          <div className='row'>
            <div className='col-lg-6 align-self-center'>
              <div className='banner-inner pe-xl-5'>
                <h6
                  className='subtitle '
                  data-aos='fade-right'
                  data-aos-delay='100'
                  data-aos-duration='1500'
                  style={{ color: 'black' }}
                >
                  DESIGNING FOR THE FUTURE
                </h6>
                <h2
                  className='title'
                  data-aos='fade-right'
                  data-aos-delay='200'
                  data-aos-duration='1500'
                  style={{ color: 'black' }}
                >
                 Custom Drones, Infinite <span>Solutions</span>
                </h2>
                <p
                  className='content pe-xl-5'
                  data-aos='fade-right'
                  data-aos-delay='250'
                  data-aos-duration='1500'
                >
                 Explore tailored drone innovation. We design, build, and customize 
                 drones for real-world applications. Elevate industries with our 
                 cutting-edge solutions. Unleash possibilities in the skies
                </p>
                {/* <Link
                  className='btn btn-border-base '
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                  to='/about'
                >
                  Discover More <FaPlus />
                </Link> */}
                <div
                  className='d-inline-block align-self-center wow animated fadeInLeft'
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                >
                  <span
                    onClick={() => setOpen(true)}
                    className='video-play-btn-hover'
                  >
                    <img src='assets/img/Group 1.svg' style={{ color: 'black' }} alt='img' />{" "}
                    <h6 className='d-inline-block' style={{ color: 'black' }}>how we work</h6>
                  </span>
                </div>
                {/* <ModalVideo
                  channel='youtube'
                  autoplay
                  isOpen={isOpen}
                  videoId='XM6kTQPzzpQ'
                  onClose={() => setOpen(false)}
                /> */}
              </div>
            </div>
            <div className='col-lg-6 col-md-10'>
              <div
                className='banner-thumb-3'
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <div className='main-img-wrap'>
                   <img src={img1}
                   id="bounceimg"
                    className='banner-animate-img banner-animate-img-1 left_image_bounce'
                    style={{ width: '150px' }} 
                    alt='img'
                  /> 
{/* 
<img src={img1}
className='banner-animate-img banner-animate-img-1 left_image_bounce'
 alt="img1" /> */}
                  {/* <img
                    className='banner-animate-img banner-animate-img-2 left_image_bounce'
                    src={img1}
                    alt='img'
                    style={{ width: '150px', marginRight: '-10%', marginBottom: '-10%' }} 
                  /> */}
                  <img
                    className='main-img'
                    src={img4}
                    // style={{marginTop: '10%'}}
                    // style={{ width: '650px', height: '550px', borderRadius: '50%' }}
                    alt='img'
                    
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerThree End ==================*/}
    </>
  );
};

export default BannerThree;
